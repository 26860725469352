@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.defaultUserFields {
}

.name {
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
  }
}

.row,
.customFields {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  font-weight: 500;

  @media (--viewportMedium) {
    width: calc(50% - 9px);
  }
}

.lastNameRoot {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 0;
    width: calc(50% - 9px);
  }
}

.email {
  composes: marketplaceModalPasswordMargins from global;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.customIcon {
  fill: none;
  height: 24px;
  margin-left: 10px;
  width: 24px;
}
